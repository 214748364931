import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Title = styled.h1`
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <main>
      <Title>404</Title>
      <h3>Diese Seite gibt es nicht</h3>
    </main>
  </Layout>
)

export default NotFoundPage
